import {ChangeDetectorRef, Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, map, tap, debounceTime } from 'rxjs/operators';
import { User } from '../models/user';
import { SchoolYear } from '../../gestion-eleves/models/school-year';



@Injectable({
  providedIn: 'root',
})
export class CrudService implements OnInit {
  apiUrlRoot: any;
  entities: any;
  entity: any;
  token: any;
  httpOptions: any;
  private currentUserSubject!: BehaviorSubject<User | null>;
  public currentUser$!: Observable<User>;
  // Créer une valeur par défaut représentant un utilisateur invité ou null si approprié
  private readonly defaultUser: User | null = null;

  private actionSubject = new BehaviorSubject<string>('');
  readonly action$ = this.actionSubject.asObservable();

  constructor(private http: HttpClient) {}
  ngOnInit(): void {}

  private retrieveFromLocalStorage<T>(key: string): T | null {
    const storedValue = localStorage.getItem(key);
    const parsedValue = storedValue ? JSON.parse(storedValue) : null;
    const subject = new BehaviorSubject<T | null>(parsedValue);
    return subject.value;
  }

  /**
   * @returns recuperer la valeur du user connecter
   */
  currentUserValue(): User | null {
    return this.retrieveFromLocalStorage<User>('currentUser');
  }

  currentSchoolYearValue(): SchoolYear | null {
    return this.retrieveFromLocalStorage<SchoolYear>('currentSchoolYear');
  }

  checkToken = () => {
    this.token = this.currentUserValue()?.accessToken;
    this.apiUrlRoot = this.token ? `${environment.apiUrl}app/` : `${environment.apiUrl}api/`;
    console.log(`val de mon token: ${this.token}`);
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };
    console.log('http option', this.httpOptions);
  };

  getEntities(entity: string): Observable<any> {
    this.checkToken();
    return this.http.get(this.apiUrlRoot + entity, this.httpOptions).pipe(
      map((response: any) => {
        this.entities = response;
        return this.entities;
      }),
    );
  }

  findEntities(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.get(this.apiUrlRoot + entity + payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entities = response;
        return this.entities;
      }),
    );
  }

  findEntities5(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    const url = this.apiUrlRoot + entity;
    const params = new HttpParams({ fromObject: payloads });
    return this.http.get(url, { params });
  }

  getElement(entity: string): Observable<any> {
    this.checkToken();
    return this.http.get(this.apiUrlRoot + entity, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        console.log('get entity element data: ', this.entity);
        return this.entity;
      }),
    );
  }

  getEntity(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.post(this.apiUrlRoot + entity, payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        console.log('get entity data: ', this.entity);
        return this.entity;
      }),
    );
  }

  private postRequest(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.post(this.apiUrlRoot + entity, payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        console.log('entity data: ', this.entity);
        return this.entity;
      }),
    );
  }

  addEntity(entity: string, payloads: any): Observable<any> {
    return this.postRequest(entity, payloads);
  }

  fetchEntityByCriteria(entity: string, payloads: any): Observable<any> {
    return this.postRequest(entity, payloads);
  }

  updateEntity(entity: string, payloads: any): Observable<any> {
    this.checkToken();
    return this.http.put(this.apiUrlRoot + entity, payloads, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        console.log('entity data: ', this.entity);
        return this.entity;
      }),
    );
  }

  deleteEntity(entity: string, id: number): Observable<any> {
    this.checkToken();
    return this.http.delete(this.apiUrlRoot + entity + '/' + id, this.httpOptions).pipe(
      map((response: any) => {
        this.entity = response;
        console.log('entity data: ', this.entity);
        return this.entity;
      }),
    );
  }

  public setAction(input: string): void {
    this.checkToken();
    this.actionSubject.next(input);
  }

  readonly autocomplete$ = (entity: string): Observable<any[]> =>
    this.action$.pipe(
      // Taps the emitted value from action stream
      tap((data: string) => console.log('input:', data)),
      // Wait for 250 ms to allow the user to finish typing
      debounceTime(250),
      // switchMap fires REST based on above input
      switchMap(input =>
        (!!input && input.trim().length > 1
          ? this.http.get<any[]>(`${(this.apiUrlRoot + entity, this.httpOptions)}/${input}`)
          : of([])
        ).pipe(
          // Additional sorting on switchMap output
          map((classes: any[]) =>
            classes.sort((classe1, classe2) => classe1.libelle.localeCompare(classe2.libelle)),
          ),
          // Taps the final emitted value from inner observable
          tap((data: any[]) => console.log('output:', data)),
        ),
      ),
    );

  downloadFile(url: string): Observable<any> {
    this.checkToken();
    return this.http.get(this.apiUrlRoot + url, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
    });
  }
}

//Unit test of bolean value in an array
