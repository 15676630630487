export enum FormRequiredMessage {
  code = 'Veuillez saisir le code envoyer',
  saisirnouveaupassword = 'Veuillez Saisissez le nouveau mot de passe',
  confirmPassword = 'Veuillez Confirmer le nouveau mot de passe',
  passwordsNotmatch = 'Les mots de passe ne correspondent pas.',
  passwordsMatch = 'Les mots de passe correspondent.',
  fieldRequire = 'Le champ est requis',
  confirmPasswordError = 'Erreur de confirmation',
  saisiradresselectronique = 'Veuillez saisir une adresse électronique valide',
  usernameOrPhoneNumber = 'Username or Phone Number is required',
  password = 'Password is required',
  lnameRequire = 'Le nom est requis',
  fnameRequire = 'Le prénom est requis',
  studentRequire = 'l\'élève est requis',
  matriculeRequire = 'Le matricule est requis',
  phoneNumberRequire = 'Le Numéro du parent est requis',
  birthDateRequire = 'La date de naissance est requise',
  schoolRequire = 'L\'etablissement est requis',
  schoolYearRequire = 'L\'année scolaire est requise',
  classeRequire = 'La salle de classe est requise',

  //Datasources
  emptyDataSourceMessage = `Aucun résultat trouvé`,
  itemsPerPageLabel = 'Éléments par page',
  nextPageLabel = 'Page suivante',
  previousPageLabel = 'Page précédente',
  firstPageLabel = 'Première page',
  lastPageLabel = 'Dernière page',
  sur = ' sur ',
}


export enum FormLabels {
  //valider component
  letUsHelpYou = 'Laissez-nous vérifier les détails de votre compte',
  resetPassword = 'Vérification OTP',
  resetMyPassword = 'Vérifier',
  code = 'code envoyer',
  saisirnouveaupassword = 'nouveau mot de passe',
  confirmPassword = 'Confirmer le nouveau mot de passe',
  backToLogin = 'Connexion?',

  //password forget component
  laisseznousvousaidez = 'Laissez-nous vous aider',
  reinitialiserlepwd = 'Réinitialiser le mot de passe',
  reinitialisermonpwd = 'Réinitialiser mon mot de passe',
  saisiradresselectronique = 'Saisissez votre adresse électronique enregistrée.',
  usernameOrPhoneNumber = "Nom d'utilisateur ou numéro de téléphone",

  //Signin component
  password = 'Mot de passe',
  title = 'Bienvenue sur transparency',
  needAccount = "Besoin d'un compte?",
  forgotPassword = 'Mot de passe oublié?',
  signIn = 'Se connecter',
  signUp = "S'inscrire",
  remember = 'Se souvenir de moi',
  login = 'Connexion',
  or = 'ou',

  //Headers
  settings = 'Paramètres',
  account = 'Compte',
  logout = 'Déconnexion',
  inbox = 'Boîte de réception',
  readAllNotifications = 'Lire toutes les notifications',
  markAllAsRead = 'Marquer tous comme lus',
  notitications = 'Notitications',

  //Card title
  card_title_note_trimestre = 'Notes trimestrielles',
  card_title_sequence = 'Notes sequentielles',
  card_title_bulletin = 'Bulletin N°',
  //Selection
  select_school_year = 'Selectionner une année scolaire',
  select_classe = 'Selectionner une classe',
  select_teacher = 'Selectionner un enseignant',
  school_year_with_data = 'Charger les données de',
  select_student = 'Sélectionner un élève',

  //console settings
  userId = 'UID: ',
  matricule = 'Matricule:',
  online = 'En ligne:',
  phoneMumber = 'Téléphone:',
  username = 'Utilisateur:',
  fname = 'Prénom:',
  prenom_nom = ' Nom et Prénom:',
  lname = 'Nom:',
  dob = 'Date de naissance',
  coefficient = 'Coefficient',
  jour = 'Jour',
  tutor_phone = 'Numéro du parent',
  typeEvaluation_libelle = 'Evaluations',
  sequence_libelle = 'Sequences',
  course_libelle = 'Matières',
  manual_registration_number = 'Matricule manuel',
  is_check_in = 'Is check in',
  is_check_out = 'Is check out',
  is_delay = 'Is delay',
  other_criteria = 'Plus de critère',
  both_are_check_out = 'Both are check',
  automatique_registration_number = 'Matricule automatique',
  chargement_en_cours = 'Chargement en cours...',
  type_registration_number = 'Type de matricule',
  categorie_staff = 'Staff',
  categorie_enseignant = 'Enseignant',
  is_current_year = 'En cours',
  is_not_current_year = 'Pas en cours',
  type_of_current_year = 'Est une année',
  date_range_first_date = 'Date inscription debut',
  date_range_start_date = 'Date debut',
  date_range_end_date = 'Date fin',
  date_range_link = ' à ',
  date_range_second_date = 'Date inscription fin',
  eleve = 'élève:',
  inscrire = 'Inscrire',
  inscrire_un = 'Inscrire un élève',
  inscrire_plus = 'Inscrire les élèves',
  plus_de_critere = 'Plus de critères',
  je_suis = 'Je suis',
  new_inscription_class = 'Nouvelle salle de classe',
  last_inscription_class = 'Ancienne salle de classe',
  delete = 'Supprimer',
  ordonner = 'Ordonner',
  associer = 'Associer',
  add = 'Ajouter',
  clone = 'Cloner',
  edit = 'Editer',
  find = 'Rechercher',
  refresh = 'Refresh',
  expireLe = 'expire le:',
  cardTrimestre = 'Trimestres',
  cardTrimestre_libelle = 'Intitule',
  cardTrimestre_code = 'Code',
  cardSequence = 'Sequences',
  cardSequence_libelle = 'Intitulé',
  cardSequence_code = 'Code',
  cardSequence_quarter = 'Trimestre',
  cardAnneeScolaire = 'Années scolaire',
  cardAnneeScolaire_fromYear = "Début d'année",
  cardAnneeScolaire_toYear = "Fin d'année",
  cardPlageHoraire = 'Plage Horaire',
  cardPlanningHoraire = 'Planning Horaire',
  cardProfil = 'Profils',
  cardProfil_libelle = 'Intitulé',
  cardProfil_isInitialized = 'Est Initialisé',
  cardLevel = 'Niveaux',
  cardLevel_libelle = 'Intitule',
  cardLevel_code = 'Code',
  cardLevel_section = 'Section',
  cardAssessment = "Type d'évaluation",
  cardAssessment_libelle = 'Intitule',
  cardAssessment_pourcentage = 'Pourcentage (en %)',
  cardAssessment_code = 'Code',
  cardGroup = 'Groupes',
  cardProgram = 'Programme',
  cardGroup_libelle = 'Intitule',
  cardGroup_code = 'Code',
  cardCourse = 'Matières',
  cardCourse_libelle = 'Intitule',
  cardCourse_code = 'Code',
  cardCourse_group = 'Groupe',
  cardCourse_initialise = 'Est Initialisé',
  cardSchool = 'Ecoles',
  cardSchool_libelle = 'Intitule',
  cardSchool_adress = 'Adresse',
  cardSchool_schoolType = 'Type école',
  cardSchool_picture = 'Picture',
  cardSchool_isDefault = 'Par défaut',
  cardSchool_url = 'Url',
  cardSchool_serverLink = 'lien http',
  cardSchool_section = 'Section',
  cardVide = 'Aucun contenu',

  cardTeacher = 'Enseignant',
  cardTeacher_lname = 'Nom',
  cardTeacher_fname = 'Prenom',
  cardTeacher_email = 'Email',
  cardTeacher_civility = 'Civilité', //Mr, Mme
  cardTeacher_adress = 'Adress',
  cardTeacher_school = 'Ecole',
  cardTeacher_categorie = 'Je suis', //enseignant, parent, staff
  cardTeacher_phoneNumber = 'Téléphone',
  cardTeacher_password = 'Mot de passe',
  cardTeacher_confirmPassword = 'Confirmer le passe',
  cardTeacher_matricule = 'Matricule',

  cardStaff = 'Staff',

  cardTutor = 'Parents',

  cardVerification = 'Vérification',
  cardVerification_etablissement = 'Etablissement',
  cardVerification_account = 'Compte utilisateur',
  cardVerification_telephone = 'N° téléphone',

  cardOption = 'Options',
  cardOption_libelle = 'Intitule',
  cardOption_code = 'Code',
  cardOption_level = 'Niveau',
  cardOption_section = 'Section',

  cardClasse = 'Classe',
  cardPlange_horaire_fromHour = 'Heure de debut',
  cardPlange_horaire_toHour = 'Heure de fin',
  cardClasse_school = 'School',
  cardClasse_option = 'Option',
  cardClasse_level = 'Niveau',
  cardClasse_hardwareId = 'Hardware ID',
  cardClasse_code = 'code',
  cardClasse_libelle = 'Intitule',

  cardGestionEleves = 'Gestion des élèves',
  cardGestionEleves_inscription = 'Gestion des inscriptions',
  cardGestionEleves_retard = 'Gestion des retards',
  cardGestionNote = 'Gestion des notes',
  cardGestionNote_note = 'note',
  cardGestionNote_report = 'Gestion des reports',

  //Modal title
  modalAddSchoolYear = 'Nouvelle année scolaire',
  modalUpdateSchoolYear = 'Editer une année scolaire',
  modalDeleteSchoolYear = 'Supprimer une année scolaire',

  modalAddLevel = 'Nouveau niveau',
  modalUpdateLevel = 'Editer un niveau',
  modalDeleteLevel = 'Supprimer un niveau',

  modalAddQuarter = 'Nouveau trimestre',
  modalUpdateQuarter = 'Editer un trimestre',
  modalDeleteQuarter = 'Supprimer un trimestre',

  modalAddProfil = 'Nouveau profil',
  modalUpdateProfil = 'Editer un profil',
  modalDeleteProfil = 'Supprimer un profil',

  modalAddSequence = 'Nouvelle sequence',
  modalUpdateSequence = 'Editer une sequence',
  modalDeleteSequence = 'Supprimer une sequence',

  modalAddGroup = 'Nouveau un groupe',
  modalUpdateGroup = 'Editer un groupe',
  modalDeleteGroup = 'Supprimer un groupe',

  modalAddProgram = 'Nouveau programme',
  modalCloneProgram = 'Clone un programme',
  modalUpdateProgram = 'Editer le programme',
  modalDeleteProgram = 'Supprimer le programme',

  modalAddPlageHoraire = 'Nouvelle plage horaire',
  modalUpdatePlageHoraire = 'Editer la plage horaire',
  modalDeletePlageHoraire = 'Supprimer la plage horaire',

  modalAddPlanningHoraire = 'Nouveau planning horaire',
  modalUpdatePlanningHoraire = 'Editer le planning horaire',
  modalDeletePlanningHoraire = 'Supprimer le planning horaire',

  modalAddCourse = 'Nouvelle matière',
  modalUpdateCourse = 'Editer une matière',
  modalDeleteCourse = 'Supprimer une matière',

  modalAddSchool = 'Nouvelle école',
  modalUpdateSchool = 'Editer une école',
  modalDeleteSchool = 'Supprimer une école',

  modalAddAssessment = "Nouveau type d'evaluation",
  modalUpdateAssessment = "Editer un type d'evaluation",
  modalDeleteAssessment = "Supprimer un type d'evaluation",

  modalAddStaff = 'Nouveau staff',
  modalUpdateStaff = 'Editer un staff',
  modalInfoStaff = 'Apropos du staff',
  modalDeleteStaff = 'Supprimer le staff',

  modalAddTeacher = 'Nouvel enseignant',
  modalUpdateTeacher = 'Editer un enseignant',
  modalInfoTeacher = "Apropos de l'enseignant",
  modalDeleteTeacher = 'Supprimer un enseignant',

  modalInfoTutor = 'Apropos du parent',
  modalDeleteTutor = 'Désactiver un parent',

  modalAddRechercherVerification = 'Rechercher les verifications',

  modalAddClasse = 'Nouvelle classe',
  modalUpdateClasse = 'Editer cette classe',
  modalOrderClasse = 'Ordonner les classes',
  modalAssocierClasseEnseignant = 'Assigner des classes',
  modalDeleteClasse = 'Supprimer cette classe',

  modalAddOption = 'Nouvelle option',
  modalUpdateOption = 'Editer une option',
  modalDeleteOption = 'Supprimer une option',

  modalAddStudent = 'Nouvel élève',
  modalAddManyInscription = 'Incrire plusieurs élèves',
  modalUpdateStudent = 'Editer un élève',
  modalDeleteStudent = 'Supprimer un élève',
  modalInfoDetail = 'Details',

  modalAddNotes = 'Ajouter/Editer des notes',
  modalUpdateNotes = 'Editer des notes',
  modalDeleteNote = 'Supprimer des notes',
  modalConfirmation = 'Confirmation',

  //Bottun name
  saveBtn = 'Sauvegarder',
  saveAllBtn = 'Inscrire tous',
  searchBtn = 'Rechercher',
  updateBtn = 'Editer',
  ordonnerBtn = 'Ordonner',
  associerClasseEnseignantBtn = 'Associer',
  cancelBtn = 'Annuler',
  refreshBtn = 'Re-initialiser',
  ouiBtn = 'Oui',
  nonBtn = 'Non',
  deleteBtn = 'Supprimer',
  cloneBtn = 'Cloner',
}

export enum FormFields {
  enterRegisteredUsernameOrPhoneNumber = 'enterRegisteredUsernameOrPhoneNumber',
  code = 'code',
  password = 'password',
  confirmPassword = 'confirmPassword',
  usernameOrPhoneNumber = 'usernameOrPhoneNumber',

  saisiradresselectronique = 'saisiradresselectronique',
}

export enum Message {
  addSuccess = 'Enregistrement effectuée...!!!',
  updateSuccess = 'Mise à jour effectuée...!!!',
  deleteSuccess = 'Suppression effectuée...!!!',
  confirmation = 'Êtes-vous sûr de vouloir supprimer cette ligne?',
  confirmerTelechargement = 'Êtes-vous telecharger ce/ces document(s)?',
}

export const FormTitleLabels = {
  modalCriteria: (title: string) => `${title}: plus de critères`,
  emptyDataSourceMessage: (schoolYear: string) =>
    `Aucun résultat trouvé pour l'annee scolaire  <b>${schoolYear}</b>`,
};
