// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { TutorService } from "src/app/gestion-eleves/services/tutor.service";

const urlDefault = 'http://localhost:4200/';
//const urlLocal = 'http://192.168.8.107:9000/';
const urlLocal = 'http://localhost:9000/';
const urlLocal00 = 'http://localhost:9001/';
const urlOnlineTest = 'https://transparency-test-0dca897e139a.herokuapp.com/';
const urlOnlineProduction = 'https://transparency-backend-java-api.herokuapp.com/';
export const environment = {
  production: false,
  apiUrl:urlOnlineTest,
};


//user_id:2 hardware_id:BJ2C222660010  date_id:2023-09-11 07:44:52am

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
