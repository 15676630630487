import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'app-feather-icons',
  templateUrl: './feather-icons.component.html',
  styleUrls: ['./feather-icons.component.scss'],
})
export class FeatherIconsComponent implements OnInit {
  @Input('icon') public icon: any;
  @Input() class: string = ''; // Ceci est pour une classe statique
  @Input() ngClass: any = {}; // Pour des classes dynamiques avec ngClass
  @Input() title!: string;
  @Input() id!: string;
  @Input() styles: { [key: string]: string } = {};

  @Output() iconClick = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}

  onIconClick(): void {
    this.iconClick.emit();
  }
}
