import { NgModule } from '@angular/core';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import {GestionNotesModule} from "./gestion-notes/gestion-notes.module";
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('./calendar/calendar.module').then((m) => m.CalendarsModule),
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('./booking/booking.module').then((m) => m.BookingModule),
      },
      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: 'gestion-eleves',
        loadChildren: () =>
          import('./gestion-eleves/gestion-eleves.module').then(
            (m) => m.GestionElevesModule
          ),
      },
      {
        path: 'gestion-notes',
        loadChildren: () =>
          import('./gestion-notes/gestion-notes.module').then(
            (m) => m.GestionNotesModule
          ),
      },
      {
        path: 'gestion-com',
        loadChildren: () =>
          import('./gestion-com/gestion-com.module').then(
            (m) => m.GestionComModule
          ),
      },
       {
        path: 'gestion-admin',
        loadChildren: () =>
          import('./gestion-admin/gestion-admin.module').then(
            (m) => m.GestionAdminModule
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
