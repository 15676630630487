import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { CodeValidation } from 'src/app/types/code-validation';
import { User } from '../models/user';
import * as AppConfig from '../url-config';
import { CrudService } from './crud.service';
import { SchoolYear } from '../../gestion-eleves/models/school-year';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentSchoolYearSubject: BehaviorSubject<SchoolYear>;
  public currentUser: Observable<User>;
  payloads: any;
  config: any;
  entity: any;

  constructor(private http: HttpClient, private crudService: CrudService) {
    const storedUser = localStorage.getItem('currentUser');
    const storedSchoolYear = localStorage.getItem('currentSchoolYear');
    const user = storedUser ? JSON.parse(storedUser) : null;
    const schoolYear = storedSchoolYear ? JSON.parse(storedSchoolYear) : null;
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentSchoolYearSubject = new BehaviorSubject<SchoolYear>(schoolYear);
    this.currentUser = this.currentUserSubject.asObservable();
    this.config = AppConfig.urlConfig;
    this.entity = this.config.user;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentSchoolYearValue(): User {
    return this.currentSchoolYearSubject.value;
  }

  login(data: any) {
    this.payloads = {
      usernameOrPhoneNumber: data.usernameOrPhoneNumber,
      password: data.password,
    };
    return this.crudService.addEntity(this.entity.login, this.payloads).pipe(
      tap((response: any) => {
        if (response.httpStatus === 'OK') {
          console.log('response: ', response);
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.currentUserSubject.next(response);
          this.crudService.currentUserValue();
        }
      }),
    );
  }

  logout(accountId: number) {
    const payload = { accountId: Number(accountId) };
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentSchoolYear');
    return this.crudService.addEntity(this.entity.logout, payload).pipe();
  }

  recupererAnneeScolaires(): Observable<SchoolYear> {
    return this.getSchoolYears().pipe(
      map(
        schoolYears =>
          schoolYears.find((schoolYear: SchoolYear) => schoolYear.currentYear) || new SchoolYear(),
      ),
      tap(schoolYear => {
        if (schoolYear) {
          localStorage.setItem('currentSchoolYear', JSON.stringify(schoolYear));
        }
      }),
      shareReplay(1),
    );
  }

  getSchoolYears = () => this.crudService.getEntities(this.entity.schoolYears);

  /**
   * Mot de passe oublié
   * @param data
   * @returns
   */
  forgotPassword = (data: { usernameOrPhoneNumber: string; optionrecuperation: string }): Observable<any> =>
    this.crudService
      .addEntity(this.entity.forgotpassword, {
        usernameOrPhoneNumber: data.usernameOrPhoneNumber,
        optionRecuperation: data.optionrecuperation,
      })
      .pipe();

  /**
   * Valider le code recu
   * @param codeValidation
   * @returns
   */
  codeValidation = (codeValidation: CodeValidation): Observable<any> =>
    this.crudService
      .addEntity(this.entity.codevalidation, {
        codeRecu: codeValidation.code,
        nouveauPassword: codeValidation.password,
        confirmationNouveauPassword: codeValidation.confirmPassword,
      })
      .pipe();

  uid = () => this.currentUserValue?.data?.account?.id ?? 0;
  identifiantEcole = () => this.currentUserValue?.data?.school?.id ?? 0;
  nomEcole = () => this.currentUserValue?.data?.school?.libelle ?? '';
  identifiantAnneeScolaire = () => this.currentSchoolYearValue?.id ?? 0;
  identifiantSection = () => this.currentUserValue?.data?.school?.section?.id ?? 0;
  /* l'opérateur de coalescence ?? pour fournir une valeur par défaut dans le cas où la valeur est undefined
  si this.currentUserValue?.data?.account?.isTeacher est undefined, la valeur par défaut false sera utilisée. */
  isTeacher = () => this.currentUserValue?.data?.account?.isTeacher ?? false;
  isTutor = () => this.currentUserValue?.data?.account?.isTutor ?? false;

  checkEndPoint = (endpoint: string) =>
    !this.isTeacher ? endpoint : endpoint + '/' + Number(this.identifiantEcole());
  checkEndPointSchool = (endpoint: string) =>
    !this.isTeacher ? endpoint : endpoint + '/school/' + Number(this.identifiantEcole());
  checkEndPointSection = (endpoint: string) =>
    !this.isTeacher ? endpoint : endpoint + '/section/' + Number(this.identifiantSection());
}
